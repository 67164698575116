

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ProvidersService from '@/modules/providers/providers.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../../cluster-deep-analysis.service';
import { DEEP_ANALYSIS_PROVIDERS } from '../../../constants';

@Component({
    components: { CustomSelect },
})
export default class ProviderFilter extends Vue {
    @inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get value() {
        return this.deepCompsetAnalysisClusterService.provider;
    }

    set value(value: typeof DEEP_ANALYSIS_PROVIDERS[number] | null) {
        this.deepCompsetAnalysisClusterService.provider = value || DEEP_ANALYSIS_PROVIDERS[0];
    }

    get items(): Item[] {
        return DEEP_ANALYSIS_PROVIDERS.map((value): Item => ({
            value,
            name: this.getProviderLabel(value),
            disabled: false,
        }));
    }

    getProviderLabel(providerName: string) {
        return this.providersService.allProviders[providerName]?.label || providerName;
    }
}
