

import { inject } from '@/inversify';
import { Vue, Component, Prop } from 'vue-property-decorator';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import TREND_TYPE from '@/modules/deep-analysis/constants/trend-type.constant';
import type TotalIndicatorData from '../../../interfaces/da-total-indicator.interface';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../../cluster-deep-analysis.service';

@Component({
    filters: {
        HotelsFilter: (value : (number)) => {
            const text = value > 1 || value === 0 ? 'Hotels' : 'Hotel';
            return `${value} ${text}`;
        },
    },
})
export default class TotalIndicator extends Vue {
    @inject(DeepCompsetAnalysisClusterServiceS) dcaClusterService!: DeepCompsetAnalysisClusterService;

    @Prop({ type: Object, default: null })
    statData!: TotalIndicatorData | null;

    @Prop({ type: Boolean })
    private hasBorder!: boolean;

    @Prop({ type: Boolean })
    private skeleton!: boolean;

    @Prop({ type: String })
    private statistic!: STATISTIC_TYPE;

    get increaseWidth() {
        if (!this.statData) {
            return 0;
        }

        return this.statData.increase / this.statData.total * 100;
    }

    get decreaseWidth() {
        if (!this.statData) {
            return 0;
        }

        return this.statData.decrease / this.statData.total * 100;
    }

    get isIncreaseActive() {
        const [statistic, trend] = this.dcaClusterService.storeState.deepAnalysisSettings.filterByStatistic;

        return this.statistic === statistic && trend === 'high';
    }

    get isDecreaseActive() {
        const [statistic, trend] = this.dcaClusterService.storeState.deepAnalysisSettings.filterByStatistic;

        return this.statistic === statistic && trend === 'low';
    }

    setStatisticFilter(trendType: 'high' | 'low' | 'no-change' | null) {
        const [statistic, trend] = this.dcaClusterService.storeState.deepAnalysisSettings.filterByStatistic;

        if (statistic === this.statistic && trendType === trend) {
            this.dcaClusterService
                .setStatisticFilter(null, null);
            return;
        }

        this.dcaClusterService
            .setStatisticFilter(this.statistic, trendType as TREND_TYPE);
    }
}
