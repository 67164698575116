
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import type HotelsService from '@/modules/hotels/hotels.service';
import type ClusterService from '../../cluster.service';

@Component({
    components: { StyledSearchbar },
})
export default class HotelSearchbar extends Vue {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    get searchValue() {
        return this.clusterService.clusterHotelSearchQuery;
    }

    get autocompleteOptions() {
        return this.hotelsService.userHotels.map(id => this.hotelsService.hotelNames[id]);
    }

    handleSearch(q: string | null) {
        this.clusterService.clusterHotelSearchQuery = q;
        // Reset pagination page
        this.clusterService.skip = 0;
    }
}
